import {LICENSE_STATUS_VALID} from "../constants";
import {createSelector} from "reselect";

const isLicenseValid = (state) => state.license.status === LICENSE_STATUS_VALID;
const getLicenseDetails = (state) => state.license.details;
const getConfig = (state) => state.config;

export const getCurrentIPOutputsCount = createSelector(
  [ getConfig ],
  (config) => {
    if(config.streamingOutput == null){
      return 0;
    }
    return Object.values(config.streamingOutput).filter(output => output.outputOrder >= 0).length;
  }
);

export const getCurrentNDIOutputsCount = createSelector(
  [ getConfig ],
  (config) => {
    if(config.NDIOutput == null){
      return 0;
    }
    return Object.values(config.NDIOutput).filter(output => output.outputOrder >= 0).length;
  }
);

export const getCurrentHardwareOutputsCount = createSelector(
  [ getConfig ],
  (config) => {
    if(config.basebandPlayer == null){
      return 0;
    }
    return Object.values(config.basebandPlayer).filter(output => output.outputOrder >= 0).length;
  }
);

export const canAddNewIPOutput = createSelector(
  [ isLicenseValid, getLicenseDetails, getCurrentIPOutputsCount ],
  (isValid, license, ipOutputCount) => {
    if(!isValid){
      return false;
    }
    return ipOutputCount < license.IPOutput;
  }
);

export const canAddNewHardwareOutput = createSelector(
  [ isLicenseValid, getLicenseDetails, getCurrentHardwareOutputsCount ],
  (isValid, license, hardwareOutputCount) => {
    if(!isValid){
      return false;
    }
    return hardwareOutputCount < license.physicalOutput;
  }
);

export const canAddNewNDIOutput = createSelector(
  [ isLicenseValid, getLicenseDetails, getCurrentNDIOutputsCount ],
  (isValid, license, NDIOutputCount) => {
    if(!isValid){
      return false;
    }
    return NDIOutputCount < license.NDIOutput;
  }
);

export const canAddNewOutput = createSelector(
  [ isLicenseValid, getLicenseDetails, getCurrentNDIOutputsCount, getCurrentHardwareOutputsCount, getCurrentIPOutputsCount ],
  (isValid, license, NDIOutputCount, hardwareOutputCount, ipOutputCount) => {
    if(!isValid){
      return false;
    }
    if(license.activeOutputs){
      return (NDIOutputCount+hardwareOutputCount+ipOutputCount) < license.activeOutputs
    }
    return (NDIOutputCount+hardwareOutputCount+ipOutputCount) < (license.IPOutput+license.NDIOutput+license.physicalOutput); 
  }
);

export const canUsePlayback = createSelector(
  [ isLicenseValid, getLicenseDetails ],
  (valid, license) => {
    if(!valid){
      return false;
    }
    return license.playback > 0;
  }
);

export const canUseEncoders = createSelector(
  [ isLicenseValid, getLicenseDetails ],
  (valid, license) => {
    if(!valid){
      return false;
    }
    return license.encoder > 0;
  }
);

export const canSetIPInput = createSelector(
  [ isLicenseValid, getLicenseDetails ],
  (valid, license) => {
    if(!valid){
      return false;
    }
    return license.IPInput || license.WebRTCInput > 0 || license.NDIInput > 0;
  }
);

export const canSetIntercom = createSelector(
  [ isLicenseValid, getLicenseDetails ],
  (valid, license) => {
    if(!valid){
      return false;
    }
    return license.intercom;
  }
);