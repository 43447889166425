import PropTypes from "prop-types";
import React, { Component } from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";

import {isEmptyString, isHostnameValid} from "../../../../utils/string-utils";
import { isPortInRange } from "../../../../utils/global-utils";
import { connect } from "react-redux";

import {
  forbiddenTCPPorts
} from "../../../../constants";
import RestartStreamhubModal from "../../tools/system/restart-streamhub-modal";


const propTypes = {
  config: PropTypes.shape({
    hostname: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

class AdvancedNetworkSettingsForm extends Component {

  constructor(props){
    super(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidation.bind(this);
    this.state = {
      confirmModalOpened: false
    };
  }

  handleConfirm(value) {
    this.setState({
      confirmModalOpened: value
    });
  }

  handleSubmit(values){
    let data = values;
    this.props.onSubmit(data);
  }

   handleValidation(values){
    const errors = {};

    //UDP port
    const forbiddenBasePorts = this.props.forbiddenUDPPorts;/*concat(values.abusProxyTcpPort);*/
    if(values.udpPort !== this.props.config["guest-interview"].udpPort){
      if(isEmptyString(values.udpPort)){
        errors.udpPort = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if(!isPortInRange(values.udpPort, 1024, 65535)){
        errors.udpPort = 'genericLabel.BASE_PORT_HELP.text';
      }
      else if(forbiddenBasePorts.indexOf(values.udpPort) !== -1 && !isPortInRange(values.udpPort, this.props.config["guest-interview"].udpPort, this.props.config["guest-interview"].udpPort+((this.props.nbInputs - 1) * 10)+3)){
        errors.udpPort = 'genericLabel.PORT_ALREADY_USED_RANGE.text';
      }
    }
    for (let port = values.udpPort; port <= values.udpPort+((this.props.nbInputs - 1) * 10)+3; port++) {
      if(isPortInRange(port, values.webrtcBasePort, values.webrtcRange+values.webrtcBasePort)){
        errors.udpPort = 'genericLabel.DUPLICATED_VALUES_RANGE.text';
        errors.webrtcBasePort = 'genericLabel.DUPLICATED_VALUES.text';
      }
    }


    //webrtcBasePort 
    if(values.webrtcBasePort !== this.props.config["guest-interview"].webrtcBasePort ){
      if(isEmptyString(values.webrtcBasePort)){
        errors.webrtcBasePort = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if(!isPortInRange(values.webrtcBasePort, 1024, 65535)){
        errors.webrtcBasePort = 'genericLabel.BASE_PORT_HELP.text';
      }
      else if(forbiddenBasePorts.indexOf(values.webrtcBasePort) !== -1 && !isPortInRange(values.webrtcBasePort, this.props.config["guest-interview"].webrtcBasePort, this.props.config["guest-interview"].webrtcBasePort + this.props.config["guest-interview"].webrtcRange+1)){
        errors.webrtcBasePort = 'genericLabel.PORT_ALREADY_USED.text';
      }
    }
    if(isEmptyString(values.webrtcRange)){
      errors.webrtcRange = 'genericLabel.REQUIRED_FIELD.text';
    } 
    else if(values.webrtcRange+values.webrtcBasePort>65535){
      errors.webrtcRange = 'genericLabel.BASE_PORT_HELP.text';
    } 
    else{
      for (let port = values.webrtcBasePort; port <= (values.webrtcRange+values.webrtcBasePort); port++) {
        if(forbiddenBasePorts.indexOf(port) !== -1 && !isPortInRange(port, this.props.config["guest-interview"].webrtcBasePort, this.props.config["guest-interview"].webrtcBasePort + this.props.config["guest-interview"].webrtcRange)){
          errors.webrtcRange = 'genericLabel.PORT_ALREADY_USED_RANGE.text';
        }
        if(isPortInRange(port, values.udpPort, values.udpPort+((this.props.nbInputs - 1) * 10)+3)){
          errors.udpPort = 'genericLabel.DUPLICATED_VALUES.text';
          errors.webrtcBasePort = 'genericLabel.DUPLICATED_VALUES.text';
        }
      }
    }
    

    //TCP port
    const forbiddenManagerPorts = this.props.forbiddenTCPPorts;/*.concat(values.atpGeneralBasePort);*/
    if(values.rtspserverPort !== this.props.config.rtspserver.port){
      if(isEmptyString(values.rtspserverPort)){
        errors.rtspserverPort = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if(!isPortInRange(values.rtspserverPort, 1024, 65535)){
        errors.rtspserverPort = 'genericLabel.MANAGER_PORT_HELP.text';
      }
      else if(forbiddenManagerPorts.indexOf(values.rtspserverPort) !== -1){
        errors.rtspserverPort = 'genericLabel.PORT_ALREADY_USED.text';
      }
    }

    if (!isHostnameValid(values.hostname)){
      errors.hostname = 'genericLabel.INVALID_FORMAT.text';
    }
    return errors;
  };

  render(){

  const { config, translate } = this.props;
  if(!config){
    return null;
  }

  return (
    <Formik initialValues={{
              hostname: config.hostname ? config.hostname : '',
              rtspserverPort: config.rtspserver.port,
              udpPort: config["guest-interview"].udpPort,
              webrtcBasePort: config["guest-interview"].webrtcBasePort,
              webrtcRange: config["guest-interview"].webrtcRange
            }}
            validate={ this.handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ this.handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => {

          const hasChanged = () => {
            return values.hostname !== config.hostname
              || values.rtspserverPort !== config.rtspserver.port
              || values.udpPort !== config["guest-interview"].udpPort
              || values.webrtcBasePort !== config["guest-interview"].webrtcBasePort
              || values.webrtcRange !== config["guest-interview"].webrtcRange
          }

          return (
            <Form onSubmit={ handleSubmit }>
            { hasChanged() &&
            <Alert color="warning">
              <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
            </Alert>
            }
              <FormGroup>
                <Label for="hostname">
                  <Translate id="genericLabel.HOSTNAME.text"/>
                </Label>
                <Input type="text"
                      name="hostname"
                      id="network_advanced_hostname"
                      invalid={errors.hostname !== undefined}
                      value={values.hostname}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.hostname}/>
                </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="rtspserverPort">
                  RTSP Server Port
                </Label>
                <Input type="number"
                      name="rtspserverPort"
                      id="network_advanced_rtspserverPort"
                      invalid={errors.rtspserverPort !== undefined}
                      placeholder={ translate('genericLabel.PORT.text') }
                      value={values.rtspserverPort}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.rtspserverPort} />
                </FormFeedback>
                <div className="indicator">
                  <Translate id="genericLabel.MANAGER_PORT_HELP.text" />
                </div>
              </FormGroup>

              <FormGroup>
                <Label for="udpPort">
                  Video return for Mojo UDP Port
                </Label>
                <Input type="number"
                      name="udpPort"
                      id="network_advanced_liveguestUdpPort"
                      invalid={errors.udpPort !== undefined}
                      placeholder={ translate('genericLabel.PORT.text') }
                      value={values.udpPort}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.udpPort} />
                </FormFeedback>
                <div className="indicator">
                  <Translate id="genericLabel.BASE_PORT_HELP.text" />
                </div>
              </FormGroup>
              <Label className="ml-2 mb-3 ">
                {`The Video return for Mojo UDP ports range from ${values.udpPort} to ${values.udpPort+((this.props.nbInputs - 1) * 10)+3}`}
              </Label>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="webrtcBasePort">
                      LiveGuest WebRTC Base Port
                    </Label>
                    <Input type="number"
                          name="webrtcBasePort"
                          id="network_advanced_liveguestWebRTCBasePort"
                          invalid={errors.webrtcBasePort !== undefined}
                          placeholder={ translate('genericLabel.PORT.text') }
                          value={values.webrtcBasePort}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                    <FormFeedback>
                      <Translate id={errors.webrtcBasePort} />
                    </FormFeedback>
                    <div className="indicator">
                      <Translate id="genericLabel.BASE_PORT_HELP.text" />
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="webrtcRange">
                    LiveGuest WebRTC Range
                    </Label>
                    <Input type="number"
                          name="webrtcRange"
                          id="network_advanced_liveguestWebRTCBasePort"
                          invalid={errors.webrtcRange !== undefined}
                          value={values.webrtcRange}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                    <FormFeedback>
                      <Translate id={errors.webrtcRange} />
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Label className="ml-2 mb-3 ">
                {`The LiveGuest WebRTC ports range from ${values.webrtcBasePort} to ${values.webrtcBasePort+values.webrtcRange}`}
              </Label>

              <FormGroup className="buttons">
              <Button id="network_product_saveButton"
                      disabled={isSubmitting || !dirty}
                      color="primary"
                      onClick={() => this.handleConfirm(true)}>
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
            { this.state.confirmModalOpened &&
            <RestartStreamhubModal onCancel={() => this.handleConfirm(false)}
                                   onConfirm={() => this.handleSubmit(values)}/>
            }
            </Form>
          )
        }
      }
    </Formik>
  )
}
};

AdvancedNetworkSettingsForm.propTypes = propTypes;

const mapStateToProps = (state) => {
  // TCP Ports
  const configTCPPorts = [];
  if(state.config.gpimng){
    configTCPPorts.push(state.config.gpimng.alarmTcpPort);
  }
  if(state.config.rtspserver){
    configTCPPorts.push(state.config.rtspserver.port);
  }

  // UDP Ports
  const forbiddenUDPPorts = state.streamhub.udpUsedPort.intercomPorts.concat(
    state.streamhub.udpUsedPort.LiveGuestPorts,
    state.streamhub.udpUsedPort.inputProtocolPorts,
    state.streamhub.udpUsedPort.intercomPorts,
    state.streamhub.udpUsedPort.channelPorts,
    state.streamhub.udpUsedPort.remoteControlPorts);
  return {
    forbiddenUDPPorts,
    forbiddenTCPPorts: forbiddenTCPPorts.concat(configTCPPorts),
    decryptedPassword: state.streamhub.decryptedPassword
  }
};

export default withLocalize(connect(mapStateToProps)(AdvancedNetworkSettingsForm));